import React, { useState, useEffect, Fragment } from "react";
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { CSSTransition } from 'react-transition-group';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import AspectRatioBox from "./aspectRatioUtil";
import TextRow from "./textRow";
import { ReactComponent as ArrowRight } from "../static/svg/arrow-right.svg";
import Loader from '../static/svg/loader.svg';
import { ReactComponent as ArrowUp } from "../static/svg/arrow-white.svg";

import { Navigation, Pagination, Mousewheel, FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/mousewheel';
import 'swiper/css/free-mode';

import configData from "../config.json";

function getMedia(path) {
  return configData.API_URL + path;
}

gsap.registerPlugin(ScrollTrigger);

function About(props) {
    let {bioSlug} = useParams();

    const [loaded, setLoaded] = useState(false);
    const [obj, setObj] = useState();
    

    const getBio = async() => {
      !obj && await axios({
        method: 'get',
        url: configData.API_URL + "/api/bio/" + bioSlug
        })
        .then(response => {
          const dataJSON = response.data;
          console.log('getBio response', dataJSON);
          dataJSON && setObj(dataJSON);
          setLoaded(true);
        });
    }


  

    useEffect(() => {
      props.accentColor('#FFFFFF');
      props.logoColor('fillBlack');
      setLoaded(true);
      // Page Meta
      document.title = "About Cloudstreet"
      document.querySelector('meta[name="description"]').setAttribute("content", "");
      document.documentElement.scrollTop = 0;

       // Anim Sequences
       let landingTl = gsap.timeline({
          defaults: {
            duration: 0.6, 
            ease: "power1"
          },
          delay: 0.25
        });
        landingTl.fromTo(".anim1.landing", {translateY: "40px"}, {translateY: "0"});
        landingTl.fromTo(".anim1.landing", {opacity: 0}, {opacity: 1},"<");

      let gridObjs = document.querySelectorAll('#row0 .grid .item');
      gridObjs.forEach((obj, i) => {
        let chain;
        if (i>0 && ((i < 3)||(i >= 4))) {
          chain = "<";
        }
        landingTl.fromTo(obj, {translateY: "40px"}, {translateY: "0"},chain);
        landingTl.fromTo(obj, {opacity: 0}, {opacity: 1},"<");
      });
      getBio();
      // getCapability();
      // getIndustries();
      
      console.log('bio', props)
    }, []);


  return (
      <CSSTransition in={loaded} timeout={0} unmountOnExit classNames="pageItem">
        <div id="bioPage">

        {obj && <div className="column textColumn"><div>
            
            <Link to={'/about?q=bio'} className='backBtn'><ArrowUp className="icon reversed fillSecondary" />Go Back </Link>
            <div className="textContainer">
           
            <img src={getMedia(obj.image)} alt={obj.image_alt} />
            <h1>{obj.name}</h1>
            <h2>{obj.title}</h2>
           {obj.text && <span dangerouslySetInnerHTML={{__html: obj.text}} />}
            </div>

       </div></div>}

         
        </div>
      </CSSTransition>
  );
}

export default About;

// <img src={getMedia(obj.image)} alt={obj.image_alt} />