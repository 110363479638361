import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { CSSTransition } from 'react-transition-group';
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { getStatic } from '../static';
import SVG, { Props as SVGProps } from 'react-inlinesvg'
import SwiperCore, { Navigation, Pagination, Mousewheel, FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";

import { ReactComponent as ArrowRight } from "../static/svg/arrow-right.svg";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/mousewheel';
import 'swiper/css/free-mode';

import configData from "../config.json";

function getMedia(path) {
  return configData.API_URL + path;
}

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

function Contact(props) {

    const [loaded, setLoaded] = useState(false);
    const [obj, setObj] = useState();

    useEffect(() => {
      let rowTl;
      if (obj) {
         // Anim Sequences
         let rowTl = gsap.timeline({
            defaults: {
              duration: 0.6, 
              ease: "power1",
            },
            // scrollTrigger: {
            //   trigger: "#row0trigger",
            //   start: "top top",
            //   end: "+=100",
            //   scrub: 1,
            //   markers: true
            // }
          });
        //  rowTl.fromTo(".anim0.row0", {translateY: "40px"}, {translateY: "0"});
        //  rowTl.fromTo(".anim0.row0", {opacity: 0}, {opacity: 1},"<");
        //  rowTl.fromTo(".anim0.row0-1a", {translateY: "40px"}, {translateY: "0"});
        //  rowTl.fromTo(".anim0.row0-1a", {opacity: 0}, {opacity: 1},"<");
        //  rowTl.fromTo(".anim0.row0-1b", {translateY: "40px"}, {translateY: "0"});
        //  rowTl.fromTo(".anim0.row0-1b", {opacity: 0}, {opacity: 1},"<");

      }
      return () => {
        rowTl && rowTl.kill();
      }
    }, [obj])


    useEffect(() => {
   
      props.menuColor('none');
      props.accentColor('#FFFFFF');
      props.logoColor('fillWhite');
      // Page Meta
      document.title = "Contact | " + configData.BASE_TITLE
      document.querySelector('meta[name="description"]').setAttribute("content", "");
      document.documentElement.scrollTop = 0;

      // Load Content
       axios({
        method: 'get',
        url: configData.API_URL + "/api/contact/"
        })
        .then(response => {
          const dataJSON = response.data;
          setObj(dataJSON)
          console.log('contact', dataJSON)
          setLoaded(true)
        });
  
    }, []);



  return (
      <CSSTransition in={loaded} timeout={200} unmountOnExit classNames="pageItem">
        <div id="contactPage">
         <div className="banner">
          {obj && <img className="auto" src={getMedia(obj.image)} alt={obj.image_alt} />}
         </div>
          {obj && <div className="column">
            <div className="contactInfo flex">
      
           
                <h1>{obj.heading}</h1>
                <div className="gridItem">
                  <h2>Email Us</h2>
                  <h3><a href={"mailto:" + obj.email}>{obj.email}</a></h3>
                </div>
                
           
            </div>
          </div>} 
        </div>
      </CSSTransition>
  );
}

export default Contact;

// <div className="gridItem">
// <h2>Address</h2>
// <h3><span dangerouslySetInnerHTML={{__html: obj.address}} /></h3>
// </div>

// <div className="gridItem">
//                   <h2>Call Us</h2>
//                   <h3><a href={"tel:+1" + obj.phone}>{obj.phone}</a></h3>
//                 </div>