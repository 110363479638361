import {Fragment, useRef, useState} from 'react'

const TextRow = (props)=>{
    const [plus, setPlus] = useState(true);    
    const moreRow = useRef();
    

    function openReadMore(e) {
        let text = moreRow.current;
        text.classList.toggle('on');
        // let plusLine = plus.current;
        setPlus(!plus)
        try {
            let parent = e.target.parentElement.parentElement.parentElement;
            parent.classList.toggle('readMoreOn')
        } catch {
            console.log('no parent')
        }
    }

    return (
        <div className="flex">
            <div className="textInner">
            {(props.h1) && <h1>
                <span dangerouslySetInnerHTML={{__html: ((props.fonts && props.h1) ? props.h1 : "") }} />
            </h1>}
            {(props.h2) && <h2>
            <span dangerouslySetInnerHTML={{__html: ((props.fonts && props.h2) ? props.h2 : "") }} />
            </h2>}
            {(props.h3) && <h3>
                <span dangerouslySetInnerHTML={{__html: ((props.fonts && props.h3) ? props.h3 : "") }} />
            </h3>}
            {(props.p) && <span style={{textAlign: 'left'}} dangerouslySetInnerHTML={{__html: ((props.fonts && props.p) ? props.p : "") }} /> }
            {(props.readmore) && <span className="readMore">
            
            {plus && 
            <span onClick={(e) => openReadMore(e)} className="readMoreBtn ui">Read more 
                <span className="plus">
                    <svg data-name="read more" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                        <rect data-name="Rectangle 553" width="12" height="12" fill="none"/>
                        <g data-name="read more animSvg">
                            <rect width="2" height="12" transform="translate(5)"/>
                            <rect width="2" height="12" transform="translate(12 5) rotate(90)"/>
                        </g>
                    </svg>
                </span>
            </span>
            }


            <span ref={moreRow} className="readMoreText" dangerouslySetInnerHTML={{__html: ((props.fonts && props.readmore) ? props.readmore : "") }} />
            
            {!plus && <span onClick={(e) => openReadMore(e)} className="readMoreBtn ui">Read less <span className="plus">
            <svg data-name="read more" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                <rect data-name="Rectangle 553" width="12" height="12" fill="none"/>
                <g data-name="read more animSvg">
                    <rect width="2" height="12" transform="translate(12 5) rotate(90)"/>
                </g>
            </svg></span></span>}


            </span>  }
            </div>
        </div>
    )
}

export default TextRow