import React, { useState, useEffect, useCallback, useRef} from "react";
import {
  BrowserRouter,
  Link,
  Routes,
  Route
} from "react-router-dom";
import { gsap } from "gsap";
import axios from 'axios';
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";

import './App.css';
import Home from './components/home';
import Contact from './components/contact';
import About from './components/about';
import Bio from './components/bio';
import Case from './components/case';
import CaseIndex from './components/caseIndex';

import { ReactComponent as Logo } from './static/svg/logo.svg';

import configData from "./config.json";

gsap.registerPlugin(MorphSVGPlugin);


function App() {

  const [menuOn, setMenuOn] = useState(false);
  const [navSm, setNavSm] = useState(false);
  const [menuClass, setMenuClass] = useState('blue');
  const [yPos, setYPos] = useState(0);
  const [footerClass, setFooterClass] = useState('auto');
  const [isMobile, setIsMobile] = useState(null);
  const [isTablet, setIsTablet] = useState(null);
  const [capabilities, setCapabilities] = useState();
  const [loaded, setLoaded] = useState(false);
  const [fontsReady, setFontsReady] = useState(false);
  const [logoColor, setLogoColor] = useState('default');
  const [accentColor, setAccentColor] = useState(null);
  const [contact, setContact] = useState();

  const menuOpenRef = useRef();
  const buttonColorRef = useRef();


  const menuStart = useCallback((runAnim) => {
    if (runAnim) {

      if (isMobile === false) {
        let menuBtn = document.getElementById('menuBtn');

      } else {
        let menuBtn_m = document.getElementById('menuBtn_m');

        
      }
        
      // gsap.to("#menuWrap", {height: "100vh", duration: 0});
  
    }
    
    
  }, [isMobile])

  const closeMenu = useCallback(() => {
    setMenuOn(false);
    console.log('closeMenu 0', buttonColorRef.current, accentColor)
    if (buttonColorRef.current) {
      
      if (isMobile === false) {
        var tl = gsap.timeline({
          defaults: {duration: 0.4, ease: "power2"},
          onStart: menuStart(false)
        });
        tl.to("#menu", {opacity:0, duration: 0.2});
        tl.to('#bar1', {morphSVG: "M0,4 L32,4", stroke: (buttonColorRef.current === "#FFFFFF")?"#000000":"#FFFFFF"}, "<");
        tl.to('#bar2', {morphSVG: "M0,16 L32,16", stroke: (buttonColorRef.current === "#FFFFFF")?"#000000":"#FFFFFF"}, "<");
        tl.to('#bar3', {opacity: 1, duration: 0.25, ease: "none", delay: "0.1"}, "<");
        tl.to('.menuBtnSq', {backgroundColor: "#ffffff"}, "<")
        menuOpenRef.current = false;
        // console.log('closeMenu', buttonColorRef.current, isMobile)
      } else {
        // console.log('closeMenu mobile', buttonColorRef.current, isMobile)
        var tl_m = gsap.timeline({
          defaults: {duration: 0.4, ease: "power2"},
          onStart: menuStart(false)
        });
        tl_m.to("#menu", {opacity:0, duration: 0.2});
        tl_m.to('#bar1_m', {morphSVG: "M0,4 L32,4", stroke: (buttonColorRef.current === "#FFFFFF")?"#000000":"#FFFFFF"}, "<-0.5");
        tl_m.to('#bar2_m', {morphSVG: "M0,16 L32,16", stroke: (buttonColorRef.current === "#FFFFFF")?"#000000":"#FFFFFF"}, "<");
        tl_m.to('#bar3_m', {opacity: 1, duration: 0.25, ease: "none"}, "<+0.5");
        tl_m.to('.menuBtnSq', {backgroundColor: "#ffffff"}, "<")
        menuOpenRef.current = false;
        
      }
    }
    
  }, [menuStart, isMobile])


  const handleMenuBtn = useCallback((menuIsOpen) => {
    console.log('handleMenuBtn 0', menuIsOpen)
    if (!menuIsOpen) {
      // console.log('handleMenuBtn Close 1', menuIsOpen, menuIsOpen, menuIdle.current)

     
        // console.log('handleMenuBtn Close 1.1', menuIsOpen, menuIsOpen, menuIdle.current)
        closeMenu(); 

   
    } else {
      // console.log('handleMenuBtn Open 0', menuIsOpen, menuIsOpen)
      if (menuIsOpen) {
        // console.log('handleMenuBtn Open 0.1', menuIsOpen, menuIsOpen)
        setMenuOn(true);
        if (isMobile === false) {
          var t2 = gsap.timeline({
            defaults: {duration: 0.2, ease: "power2"},
            onStart: menuStart(true)
          });
          t2.to('#bar3', {opacity: 0, duration: 0.15, ease: "none"}, "<");
          t2.to('#bar1', {morphSVG: "M1,1 L31,31", stroke: "#ffffff"}, "<");
          t2.to('#bar2', {morphSVG: "M1,31 L31,1", stroke: "#ffffff"}, "<");
          t2.to('.menuBtnSq', {backgroundColor: "#000000"}, "<")
          t2.to("#menu", {opacity: 1}, "<");
        } else {
          var t2_m = gsap.timeline({
            defaults: {duration: 0.4, ease: "power2"},
            onStart: menuStart(true)
          });
          t2_m.to('#bar3_m', {opacity: 0, duration: 0.15, ease: "none"}, "<");
          t2_m.to('#bar1_m', {morphSVG: "M1,1 L31,31", stroke: "#ffffff"}, "<");
          t2_m.to('#bar2_m', {morphSVG: "M1,31 L31,1", stroke: "#ffffff"}, "<");
          t2_m.to('.menuBtnSq', {backgroundColor: "#000000"}, "<")
          t2_m.to("#menu", {opacity: 1}, "<");
        }
        }
       

    }
  }, [isMobile, menuStart, closeMenu]);

  function postRouteChange(){
    // console.log('postRouteChange')
    setAccentColor(null);
    
  }


  useEffect(() => {
   if (accentColor) {
      
      buttonColorRef.current = accentColor;
      // console.log('accentColor', accentColor, buttonColorRef.current)
      if (isMobile === false) {
        gsap.to('#bar1', {stroke: (buttonColorRef.current === "#FFFFFF")?"#000000":"#FFFFFF"});
        gsap.to('#bar2', {stroke: (buttonColorRef.current === "#FFFFFF")?"#000000":"#FFFFFF"});
        gsap.to('#bar3', {stroke: (buttonColorRef.current === "#FFFFFF")?"#000000":"#FFFFFF"});
      } else {
        gsap.to('#bar1_m', {stroke: (buttonColorRef.current === "#FFFFFF")?"#000000":"#FFFFFF"});
        gsap.to('#bar2_m', {stroke: (buttonColorRef.current === "#FFFFFF")?"#000000":"#FFFFFF"});
        gsap.to('#bar3_m', {stroke: (buttonColorRef.current === "#FFFFFF")?"#000000":"#FFFFFF"});
      }

      if (buttonColorRef.current === accentColor) {
        closeMenu();
        setYPos(0);
      } 
      
   }
    
  }, [accentColor])

  useEffect(() => {
    let menuCornerBtn_m;
    let menuCornerBtn;
    let menuBtn_mL;
    let menuBtnL;
    
    if (isMobile !== null) {
      if (isMobile === false) {
        menuCornerBtn = document.getElementById('menuBtnSq')
        menuBtnL = menuCornerBtn.addEventListener("click", function(){ 
          
          if (menuOpenRef.current) {
            menuOpenRef.current = false;
          } else {
            menuOpenRef.current = true;
          }
          handleMenuBtn(menuOpenRef.current); 
          console.log("menu click", menuOpenRef.current)
      });
  
    } else {
      console.log('setup menu btn listener: mobile')
      menuCornerBtn_m = document.getElementById('menuBtn_m')
      menuBtn_mL = menuCornerBtn_m.addEventListener("click", function(){ 
        
        if (menuOpenRef.current) {
          menuOpenRef.current = false;
        } else {
          menuOpenRef.current = true;
        }
        handleMenuBtn(menuOpenRef.current); 
       });
    }
    }
     
  return () => {
    menuCornerBtn && menuBtnL && menuCornerBtn.removeEventListener('click', menuBtnL);
    menuCornerBtn_m && menuBtn_mL && menuCornerBtn_m.removeEventListener('click', menuBtn_mL);
  }

  }, [isMobile, handleMenuBtn])

  
  useEffect(() => {
    menuOpenRef.current = false;
    buttonColorRef.current = "#FFFFFF";
    document.title = configData.BASE_TITLE
    document.querySelector('meta[name="description"]').setAttribute("content", "");

    setLoaded(true);
    setFontsReady(true);

    var width = document.documentElement.clientWidth;
    if (width > 600) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }

    axios({
      method: 'get',
      url: configData.API_URL + "/api/contact/"
      })
      .then(response => {
        const dataJSON = response.data;
        setContact(dataJSON)
      });


    // var cssId = 'cssFonts';
    // var head  = document.getElementsByTagName('head')[0];
    // var cssElem = document.getElementById(cssId);
    // if (!cssElem)
    // {
    //     var link  = document.createElement('link');
    //     link.id   = cssId;
    //     link.rel  = 'stylesheet';
    //     link.type = 'text/css';
    //     link.href = 'https://cloud.typography.com/7604932/7997632/css/fonts.css';
    //     link.media = 'all';
    //     link.onload = function(){ setFontsReady(true) };
    //     head.appendChild(link);
    // }  


  }, []);


  return (

      <div className={"app" + ((menuOn)?(" menuOn"):(""))}>
        <header className={"column " + ((navSm)?(" navSm"):(""))} id="top">
          <a className="skip-main" href="#core" aria-label="skip to main" role="navigation">Skip to main content</a>
          <Link aria-label="home" id="logo" to="/" onClick={() => handleMenuBtn(false)}><Logo className={logoColor} /></Link>
          <div className="menuWrap" id="menuWrap">
            <section id="menu" className="mainMenu"> 
              <nav aria-label="top nav">
                <ul>
                  <li><Link to="/work" onClick={() => postRouteChange()}>Work</Link></li>
                  <li><Link to="/about" onClick={() => postRouteChange()}>About</Link></li>
                  <li><Link to="/contact" onClick={() => postRouteChange()}>Contact</Link></li>
                </ul>
              
              </nav>    
            </section>
            {isMobile ? <span className="menuBtnSq" id="menuBtnSq_m"><svg id="menuBtn_m" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
            <g>
              <path id="bar1_m" d="M0,4 L32,4" fill="none" stroke="#fff" strokeWidth="2"/>
              <path id="bar2_m" d="M0,16 L32,16" fill="none" stroke="#fff" strokeWidth="2"/>
              <path id="bar3_m" d="M0,28 L32,28" fill="none" stroke="#fff" strokeWidth="2"/>
            </g>
          </svg></span> : <span className="menuBtnSq" id="menuBtnSq"><svg id="menuBtn" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
          <g>
            <path id="bar1" d="M0,4 L32,4" fill="none" stroke="#fff" strokeWidth="2"/>
            <path id="bar2" d="M0,16 L32,16" fill="none" stroke="#fff" strokeWidth="2"/>
            <path id="bar3" d="M0,28 L32,28" fill="none" stroke="#fff" strokeWidth="2"/>
          </g>
        </svg></span>  }

            
            
        
          </div>
        </header>
       
        <section id="core">
       
          <Routes>
            <Route path="/" index element={<Home fonts={fontsReady} isMobile={isMobile} logoColor={setLogoColor} accentColor={setAccentColor} footerColor={setFooterClass} />} />
            <Route path="contact/" element={ <Contact fonts={fontsReady} isMobile={isMobile} menuColor={setMenuClass} accentColor={setAccentColor} logoColor={setLogoColor} footerColor={setFooterClass} />} />
            <Route path="about/" element={ <About fonts={fontsReady} isMobile={isMobile} menuColor={setMenuClass} accentColor={setAccentColor} logoColor={setLogoColor} footerColor={setFooterClass} />} /> 
            <Route path="about/:bioSlug" element={ <Bio fonts={fontsReady} isMobile={isMobile} menuColor={setMenuClass} accentColor={setAccentColor} logoColor={setLogoColor} footerColor={setFooterClass} />} /> 
            <Route path="work/" element={ <CaseIndex fonts={fontsReady} isMobile={isMobile} menuColor={setMenuClass} accentColor={setAccentColor} logoColor={setLogoColor} footerColor={setFooterClass} />} />
            <Route path="work/:caseSlug" element={ <Case fonts={fontsReady} isMobile={isMobile} menuColor={setMenuClass} accentColor={setAccentColor} logoColor={setLogoColor} footerColor={setFooterClass}/>} />
          </Routes>
       
      
        </section>
        <footer className={footerClass}>
     
              <section className="column">
               
                <h2>We look forward to hearing from you.</h2>
                <h3>For new business or general inquiries, please email us: {contact && <a href={"mailto:" + contact.email} target="_blank" rel="noreferrer">{contact.email}</a>}</h3>
                
                <div className="copyright">
                
                <p>{contact && contact.address.replaceAll('<br/>','')}</p>
                <p>&copy; {new Date().getFullYear()} Cloudstreet</p> 
              </div>
              </section>
   


        </footer>
      </div>

  );
}

export default App;