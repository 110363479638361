import React, { useState, useEffect, Fragment } from "react";
import { Link, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { CSSTransition } from 'react-transition-group';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import AspectRatioBox from "./aspectRatioUtil";
import TextRow from "./textRow";
import { ReactComponent as ArrowRight } from "../static/svg/arrow-right.svg";
import Loader from '../static/svg/loader.svg';
import { ReactComponent as ArrowUp } from "../static/svg/arrow-white.svg";

import { Navigation, Pagination, Mousewheel, FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/mousewheel';
import 'swiper/css/free-mode';
import classNames from 'classnames';

import configData from "../config.json";

function getMedia(path) {
  return configData.API_URL + path;
}

gsap.registerPlugin(ScrollTrigger);

function About(props) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [loaded, setLoaded] = useState(false);
    const [about, setAbout] = useState();
    const [clientRow, setClientRow] = useState();
    const [quoteRow, setQuoteRow] = useState();
    const [teamRow, setTeamRow] = useState();
    const [joinRow, setJoinRow] = useState();
    const [fetchIndex, setFetchIndex] = useState();

    const fetchArray = [
      {
        "path": "/api/client-row",
        "setObj": setClientRow,
        "animId": 2
      },
      {
        "path": "/api/quote-row",
        "setObj": setQuoteRow,
        "animId": 3
      },
      {
        "path": "/api/team-row",
        "setObj": setTeamRow,
        "animId": 4
      },
      {
        "path": "/api/join-row",
        "setObj": setJoinRow,
        "animId": 5
      }
    ]

    const getRow = async(fetchObj) => {
      await axios({
        method: 'get',
        url: configData.API_URL + fetchObj['path']
        })
        .then(response => {
          const dataJSON = response.data;
          fetchObj['setObj'](dataJSON);
          console.log('rows updated', dataJSON)
        });

        let elemId = 'rowTrigger_' + fetchObj['animId'];
        function scrollLoad() {
        //  console.log('scrollLoad')
          let rowTl = gsap.timeline({
            defaults: {
              duration: 0, 
              ease: "power1",
            },
            scrollTrigger: {
              trigger: "#" + elemId,
              start: "center bottom",
              end: "bottom bottom",
              scrub: 0,
              markers: false,
              onStart: setFetchIndex(fetchIndex + 1)
            }
          });

          setTimeout(() => {
            let rowTlAnim = gsap.timeline({
              defaults: {
                duration: 0.4, 
                ease: "power1"
              },
              scrollTrigger: {
                trigger: "#" + elemId,
                start: "top center+=35%",
                end: "bottom bottom",
                scrub: 1,
                markers: false
              }
            });
            rowTlAnim.fromTo('#rowBox_' + fetchObj['animId'], {translateY: "40px"}, {translateY: "0"});
            rowTlAnim.fromTo('#rowBox_' + fetchObj['animId'], {opacity: 0}, {opacity: 1},"<"); 

          }, 300);
        }
        scrollLoad();  
    }

    const getAbout = async() => {
      !about && await axios({
        method: 'get',
        url: configData.API_URL + "/api/about-intro/"
        })
        .then(response => {
          const dataJSON = response.data;
          console.log('getAbout response', dataJSON);
          dataJSON && setAbout(dataJSON[0]);
          setFetchIndex(0);
          // setRowIndex(0);
          
        });
    }

    useEffect(() => {
      if (searchParams && teamRow) {
        let p = searchParams.get("q");
        if (p === 'bio') {
         gsap.to(window, {duration: 0.5, scrollTo: {y: "#teamRow", offsetY: -50}})
        }
      }
    }, [searchParams, teamRow])


    useEffect(() => {
      if (fetchIndex > -1) {
        console.log('rowIndex', fetchIndex) 
        if (fetchArray[fetchIndex]) {
          getRow(fetchArray[fetchIndex])
        }
        // if (about.rows[rowIndex]) {
        //   getRow(about.rows[rowIndex].id)
        // }
      }
  }, [fetchIndex])

    useEffect(() => {
      props.accentColor('#FFFFFF');
      props.logoColor('fillBlack');
      setLoaded(true);
      // Page Meta
      document.title = "About Cloudstreet"
      document.querySelector('meta[name="description"]').setAttribute("content", "");
      document.documentElement.scrollTop = 0;

       // Anim Sequences
       let landingTl = gsap.timeline({
          defaults: {
            duration: 0.6, 
            ease: "power1"
          },
          delay: 0.25
        });
        landingTl.fromTo(".anim1.landing", {translateY: "40px"}, {translateY: "0"});
        landingTl.fromTo(".anim1.landing", {opacity: 0}, {opacity: 1},"<");

      let gridObjs = document.querySelectorAll('#row0 .grid .item');
      gridObjs.forEach((obj, i) => {
        let chain;
        if (i>0 && ((i < 3)||(i >= 4))) {
          chain = "<";
        }
        landingTl.fromTo(obj, {translateY: "40px"}, {translateY: "0"},chain);
        landingTl.fromTo(obj, {opacity: 0}, {opacity: 1},"<");
      });
      getAbout();
      // getCapability();
      // getIndustries();

      
      
      console.log('about', props)
    }, []);


  return (
      <CSSTransition in={loaded} timeout={0} unmountOnExit classNames="pageItem">
        <div id="aboutPage">
          {about && 
          <div className="full">
            <div className="full aboutBanner">
              <TextRow fonts={true} h1={about.heading} h2={about.subheading} h3={null} p={about.text} readmore={about.text_more} /> 
            </div>
            {about.principles && about.principles.map((principle, i) => (<div key={'p' + i} className="flex">
              <h2 id={'principleHeading_' + i}>{principle.heading}</h2>
              {principle.text && <h3 dangerouslySetInnerHTML={{__html: principle.text}} />}
              <div className={classNames(
                "grid",
                "grid" + principle.principles.length
              )}>
              {principle.principles.map((obj, i) => (
                <span className="gridItem alignLeft" key={'principle_' + i}>
                {obj.file && <img src={getMedia(obj.file)} alt={obj.file_alt} />}
                  <h3 dangerouslySetInnerHTML={{__html: obj.heading}}></h3>
                  {<span dangerouslySetInnerHTML={{__html: obj.text}} />}
                </span>
              ))}
              </div>

            </div>))}
          </div>
          }
           
          {clientRow && <div className="flex">
          <span id={"rowTrigger_2"} className="animTrigger1"></span>
          <h2>{clientRow.heading}</h2>
          <div className="grid grid5">
          {clientRow.clients.map((obj, i) => (
            <span className="gridItem" key={'client_' + i}>
              <img src={getMedia(obj.file)} alt={obj.file_alt} />
            </span>
          ))}
          </div></div>
          }

          {quoteRow && <div style={{display: "none"}} className="flex column">
            <span id={"rowTrigger_3"} className="animTrigger1"></span>

            <span><h2>{quoteRow.heading}</h2></span>
            <div className="swiperColumn quoteSwiper" id={"rowSwiper_3"}>
              <Swiper
                modules={[Navigation,Pagination]}
                init={false}
                className="swiper-slide"

                breakpoints={{
                  0: {
                    slidesPerView: 2,
                    spaceBetween: 0
                  },
                  820: {
                    slidesPerView: 2,
                    spaceBetween: 0
                  },
                  1550: {
                    slidesPerView: 2,
                    spaceBetween: 0
                  },
                  2800: {
                    slidesPerView: 4,
                    spaceBetween: 0
                  }
                }}
                pagination={{ clickable: true, type: "bullets", el: "#swiperNav_3"}}
                >
                {quoteRow.quotes && quoteRow.quotes.map((obj, i) => (
                  <SwiperSlide 
                    key={"quote_" + i}
                  >
                    <span
                      className={"quoteSlide"}
                      id={"slide_" + i} 
                      >
                      <h3>{obj.heading}</h3>
                      <p>{obj.text}</p>
                      <p className="sm">- {obj.source}</p>
                    </span>
                  </SwiperSlide>
                  ))}
                
              </Swiper>  
              <div className="full">
                <div className="swiperNav" id={"swiperNav_3"}></div>
              </div> 
            </div>
            </div>}


            {teamRow && 
              <div className="row bioRow" id="teamRow">
              <div className="full">
  
                <div className="flex">
                <h2>{teamRow.heading}</h2>
                  <div className="grid grid5">
                  {teamRow.bios && teamRow.bios.map((obj, i) => (
                    <div key={'people_' + i} className="item">
                      <div className="imageBox">
                       
                        
                        <img src={getMedia(obj.image)} alt={obj.image_alt} />
                        {obj.hover_text && <div className="overlay">
                          <Link to={obj.get_absolute_url} className="overlayInner">
                            <h3>{obj.hover_text}</h3>
                            <span className="learnBox">
                            <div className="learn">
                              <p>See Bio</p>
                              <ArrowUp className="icon" />
                            </div>
                            </span>
                          </Link>
                        </div>}
                    </div>
                   
                   
                    <span>
                      <h4>{obj.name}</h4>
                      <p>{obj.title}</p>
                      </span>
                    </div>
                  ))}
                  </div>
                 
                </div>
              </div>
            </div>
          }

          {joinRow && 
            <div className="row joinRow">
            <div className="full">

              <div className="flex">
              <h2 className="pad0">{joinRow.heading}</h2>
              <h3>{joinRow.subheading}</h3>
                <img className="auto" src={getMedia(joinRow.image)} alt={joinRow.image_alt} />

                <Link to={joinRow.link}><p className="joinCta">{joinRow.link_heading} <ArrowUp className="icon fillSecondary" /></p></Link>
              </div>
            </div>
          </div>
        }

        </div>
      </CSSTransition>
  );
}

export default About;

// <img src={getMedia(obj.image)} alt={obj.image_alt} />