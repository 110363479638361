import React, { useState, useEffect, Fragment } from "react";
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { CSSTransition } from 'react-transition-group';
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import AspectRatioBox from "./aspectRatioUtil";
import TextRow from "./textRow";
import { getStatic } from '../static';
import { ReactComponent as ArrowUp } from "../static/svg/arrow-white.svg";

import configData from "../config.json";

function getMedia(path) {
  return configData.API_URL + path;
}

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

function sizeOverlay(){
  let colorOverlays = document.getElementsByClassName('colorOverlay');
  for (var i = 0; i < colorOverlays.length; i++) {
    let w = colorOverlays[i].offsetWidth;
    let h = colorOverlays[i].offsetHeight;
    let o = colorOverlays[i].parentElement.getElementsByClassName('overlay')[0];
    o.style.width = w + "px";
    o.style.height = h + "px";
  }
}

function CaseIndex(props) {

    const [loaded, setLoaded] = useState(false);
    const [objs, setObjs] = useState();
    const [intro, setIntro] = useState();

    const getIntro = async() => {
      await axios({
        method: 'get',
        url: configData.API_URL + "/api/case-intro/"
        })
        .then(response => {
          const dataJSON = response.data;
          setIntro(dataJSON);
        });
    }


    const getObjs = async() => {
      await axios({
        method: 'get',
        url: configData.API_URL + "/api/case-list/"
        })
        .then(response => {
          const dataJSON = response.data;
          setObjs(dataJSON);
        });
    }
    

    useEffect(() => {
      let rowTl;
      if (objs) {
        objs.forEach((o, i) => {
           let rowTl = gsap.timeline({
            defaults: {
              duration: 0.6, 
              ease: "power1",
            },
            scrollTrigger: {
              trigger: "#triggerCaseId_" + o.id,
              start: "top 80%",
              end: "+=100",
              scrub: 1,
              markers: false
            }
          });
         rowTl.fromTo("#caseId_" + o.id, {translateY: "40px"}, {translateY: "0"});
         rowTl.fromTo("#caseId_" + o.id, {opacity: 0}, {opacity: 1},"<");
        });

      }
      // return () => {
      //   rowTl && rowTl.kill();
      // }
    }, [objs])

    useEffect(() => {
        if (objs) {
            setLoaded(true);
            console.log('case studies loaded');    
            // console.log('cases', objs)
        }
    }, [objs])

    useEffect(() => {
      props.menuColor('none');
      props.accentColor('#FFFFFF');
      props.logoColor('fillBlack');
      // Page Meta
      document.title = ""
      document.querySelector('meta[name="description"]').setAttribute("content", "");
      document.documentElement.scrollTop = 0;

      // Load Content
      getObjs();
      getIntro();
    }, []);




  return (
      <CSSTransition in={loaded} timeout={200} unmountOnExit classNames="pageItem">
         <div id="content" className="caseIndex">
          <div id="caseIndex">
          <span className="row" id="row0">
            <div className="anim1 landing">

              {intro && <TextRow fonts={true} h1={intro.heading} h2={intro.subheading} h3={null} p={intro.text} readmore={intro.text_more} /> }      

            </div>

          </span>
            <div className="grid gridCaseIndex">
            {objs && objs.map((obj, i) => (
   
                <Link to={obj.get_absolute_url}
                data-block={"case" + (i + 1)}
                key={'case_' + i}
                id={'caseId_' + obj.id}
                style={{
                  backgroundColor: obj.grid_color?obj.grid_color:"#1a1a1a",
                  // paddingTop: "100%"
                }}
                className={"item arBox caseIndexBox"}
                >
                <span className={"animTrigger"} id={'triggerCaseId_' + obj.id}></span>
                <span className="arBoxIn">
                  <span className="caseIndexBoxIn">
                    <img src={props.isMobile?getMedia(obj.thumbnail_m):getMedia(obj.thumbnail)} alt={obj.image_alt} />
                    <span className="textRow">
                      <h2>{obj.name}</h2>
                     
                      <div className="learn">
                      <p>View case study</p>
                      <ArrowUp className="icon" />
                      </div>
                    </span>
                  </span>
                </span>
              </Link>
            
            ))}
            </div>
          </div>
        </div>
      </CSSTransition>
  );
}

export default CaseIndex;


