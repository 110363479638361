import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { CSSTransition } from 'react-transition-group';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import AspectRatioBox from "./aspectRatioUtil";
import { ReactComponent as ArrowRight } from "../static/svg/arrow-right.svg";
import Loader from '../static/svg/loader.svg';
import { Navigation, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/mousewheel';
import 'swiper/css/free-mode';
import classNames from 'classnames';
import configData from "../config.json";

function getMedia(path) {
  return configData.API_URL + path;
}

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(Autoplay);

function Home(props) {

    const [loaded, setLoaded] = useState(false);
    const [capabilities, setCapabilities] = useState();
    const [industries, setIndustries] = useState();
    const [recentWorkIntro, setRecentWorkIntro] = useState();
    const [values, setValues] = useState();
    const [home, setHome] = useState();
    const [rows, setRows] = useState([]);
    const [rowIndex, setRowIndex] = useState(-1);
    const [clientIntro, setClientIntro] = useState();
    const [peopleIntro, setPeopleIntro] = useState();


    const getRow = async(objId) => {
      await axios({
        method: 'get',
        url: configData.API_URL + "/api/home-row/" + objId + '/'
        })
        .then(response => {
          const dataJSON = response.data;
          let newRows = [...rows ];
          newRows[rowIndex] = dataJSON;
         
          setRows(newRows);
          console.log('rows updated', newRows)
        });

       
    }

    const getHome = async() => {
      !home && await axios({
        method: 'get',
        url: configData.API_URL + "/api/home/"
        })
        .then(response => {
          const dataJSON = response.data;
          console.log('getHome response', dataJSON);
          dataJSON && setHome(dataJSON[0]);
          setRowIndex(0);
          
          
        });
    }


    useEffect(() => {
      if (rowIndex > -1) {
        console.log('rowIndex', rowIndex) 
        if (home.rows[rowIndex]) {

          getRow(home.rows[rowIndex].id)
        }
      }
  }, [rowIndex])


  useEffect(() => {
    console.log('rows', rows,rowIndex)
    if (rows && (rows.length > 0) && (rowIndex > -1) && rows[rowIndex]) {
      let elemId = "rowTrigger_" + rows[rowIndex].id;
      let rowId = rows[rowIndex].id;
      console.log('elemId',elemId)
      function scrollLoad() {
       console.log('scrollLoad')
        gsap.timeline({
          defaults: {
            duration: 0, 
            ease: "power1",
          },
          scrollTrigger: {
            trigger: "#" + elemId,
            start: "center bottom",
            end: "bottom bottom",
            scrub: 0,
            markers: false,
            onEnter: () => { setRowIndex(rowIndex + 1) }
          }
        });

        setTimeout(() => {
          let rowTlAnim = gsap.timeline({
            defaults: {
              duration: 0.4, 
              ease: "power1"
            },
            scrollTrigger: {
              trigger: "#" + elemId,
              start: "top center+=35%",
              end: "bottom bottom",
              scrub: 1,
              markers: false
            }
          });
          rowTlAnim.fromTo('#rowBox_' + rowId, {translateY: "40px"}, {translateY: "0"});
          rowTlAnim.fromTo('#rowBox_' + rowId, {opacity: 0}, {opacity: 1},"<"); 

          let rowTlAnim2 = gsap.timeline({
            defaults: {
              duration: 0.4, 
              ease: "power1"
            },
            scrollTrigger: {
              trigger: "#rowSwiper_" + rowId,
              start: "top center+=25%",
              end: "bottom bottom",
              scrub: 1,
              markers: false
            }
          });
          rowTlAnim2.fromTo('#rowSwiper_' + rowId, {translateY: "40px"}, {translateY: "0"});
          rowTlAnim2.fromTo('#rowSwiper_' + rowId, {opacity: 0}, {opacity: 1},"<"); 
        }, 300);
      }

      scrollLoad();  
    }
  }, [rowIndex,rows])

    useEffect(() => {
      props.accentColor('#FFFFFF');
      props.logoColor('fillWhite');
      setLoaded(true);
      // Page Meta
      document.title = configData.BASE_TITLE
      document.querySelector('meta[name="description"]').setAttribute("content", "We help elevate the relationship between people and brands through impactful moments of connection.");
      document.documentElement.scrollTop = 0;

       // Anim Sequences
       let landingTl = gsap.timeline({
          defaults: {
            duration: 0.6, 
            ease: "power1"
          },
          delay: 0.25
        });
        landingTl.fromTo(".anim1.landing", {translateY: "40px"}, {translateY: "0"});
        landingTl.fromTo(".anim1.landing", {opacity: 0}, {opacity: 1},"<");

      let gridObjs = document.querySelectorAll('#row0 .grid .item');
      gridObjs.forEach((obj, i) => {
        let chain;
        if (i>0 && ((i < 3)||(i >= 4))) {
          chain = "<";
        }
        landingTl.fromTo(obj, {translateY: "40px"}, {translateY: "0"},chain);
        landingTl.fromTo(obj, {opacity: 0}, {opacity: 1},"<");
      });
      getHome();

      
      console.log('home', props)
    }, []);


function getHomeFile(id, path, aspectRatio, aspectRatioM, fileType) {
  let span = document.getElementById(id);
  let width = "auto";
  let height = "auto";
  let paddingTop = "auto";
  console.log('getHomeFile', span, id)
  if (span) {
    if (props.isMobile) {
      if (aspectRatioM) {
        width = "100vw"
        paddingTop = 1/aspectRatioM * 100;
      }
    } else {
      if (aspectRatio) {
        width = "100vw"
        paddingTop = 1/aspectRatio * 100;
      }
      span.setAttribute('style', 'width: ' + width + "; padding-top: calc(" + paddingTop + "%)");
      span.parentElement.setAttribute('style', "height: " + height + ";")
    }
    
    

  }
}


  return (
      <CSSTransition in={loaded} timeout={0} unmountOnExit classNames="pageItem">
    
         <div id="home">
          
          {home && <div id="landing">
          {(home.file || home.file_m)  && <div className="banner"><video poster={Loader} playsInline muted autoPlay loop preload="yes"><source src={props.isMobile?getMedia(home.file_m?home.file_m:home.file):getMedia(home.file)} preload={"none"} type={props.isMobile?"video/mp4":"video/webm"}/></video></div> }

            
          </div>}
           
          {rows && rows.map((obj0, i) => (
            <div className="row" key={"row_" + i}>
            <span id={"rowTrigger_" + obj0.id} className="animTrigger1"></span>
              <div className="column">              
                <div className={classNames(
                  "flex",
                  (obj0.subheading && "flexLong")
                )} id={"textFlex_" + obj0.id}>
                  <div className="textBlock anim0" id={"rowBox_" + obj0.id}>
                    {obj0.heading && <h2>
                      <span dangerouslySetInnerHTML={{__html: ((props.fonts && obj0.heading) ? obj0.heading : "") }}></span>
                    </h2>}
          
                    {obj0.subheading &&  <h3><br/><span dangerouslySetInnerHTML={{__html: ((props.fonts && obj0.subheading) ? obj0.subheading : "") }}></span></h3>}

                    {obj0.text && <h3><span dangerouslySetInnerHTML={{__html: ((props.fonts && obj0.text) ? obj0.text : "") }}></span></h3>}

                  </div>
                </div>
              </div>
             
              <span id={"rowTrigger_" + obj0.id + "_swiper"} className="animTrigger1"></span>
                <div className="swiperColumn anim0" id={"rowSwiper_" + obj0.id}>
                  <Swiper
                    modules={[Navigation,Pagination,Autoplay]}
                    initialSlide={0}
                    className="swiper-slide"
                    slidesPerView={"auto"}
                    autoplay={{
                      delay: 3000
                    }}
                    speed={1000}
                    loop
                    pagination={{ clickable: true, type: "bullets", el: "#swiperNav_" + obj0.id }}
                    breakpoints={{
                      0: {
                        slidesPerView: 1,
                        spaceBetween: 20
                      },
                      901: {
                        slidesPerView: 'auto',
                        spaceBetween: 20
                      }
                    }}
                    >
                    {obj0.rowitems && obj0.rowitems.map((obj, i) => (
                      <SwiperSlide 
                        key={"work_" + i}
                      >
                        <Link to={obj.link && obj.link} 
                          className={"slide"}
                          id={"slide_" + i} 
                          >
                          {obj.caption && <span className="caption"><p>{obj.caption}</p></span>}
                          <img src={props.isMobile?getMedia(obj.file_m?obj.file_m:obj.file):getMedia(obj.file)} alt={obj.file_alt} />
                         
                        </Link>
                      </SwiperSlide>
                      ))}
                    
                  </Swiper>  
                <div className="column">
                  <div className="swiperNav" id={"swiperNav_" + obj0.id}></div>
                </div> 
              </div>
            </div>
            
          ))}
           
        
          
        </div>
      </CSSTransition>
  );
}

export default Home;

// <img src={getMedia(obj.image)} alt={obj.image_alt} />